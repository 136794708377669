import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const PrivacyPage = () => (
  <Layout>
    <SEO title="EasyBusy: Privacy Policy" />
    <div className={'container'}>
      <div className={'content policies'}>
        <div className={'title'}>
          <h1>Privacy Policy</h1>
        </div>

        <p className="c0">
          <span className="c2">
            NEW_OWNER. (&ldquo;NEW_OWNER,&rdquo; &ldquo;we,&rdquo;
            &ldquo;us,&rdquo; and &ldquo;our&rdquo;) an effective way to manage your
            tasks and projects. Our Privacy Policy (&ldquo;Privacy Policy&rdquo;) is
            designed to help you understand how we collect, use and share your
            personal information and to assist you in exercising the privacy rights
            available to you.
          </span>
        </p>
        <h2 className="c8" id="h.s68ptapv7k7n">
          <span className="c5 c3">Scope</span>
        </h2>
        <p className="c0">
          <span>
            This Privacy Policy covers the personal information we collect about you
            when you use our products or services, or otherwise interact with us,
            including on our website at{' '}
          </span>
          <span>
            <a className="c1" href="https://easybusydo.com">
              easybusydo.com
            </a>
          </span>
          <span>, mobile applications (&ldquo;</span>
          <span className="c3">Apps</span>
          <span>
            &rdquo;), and our related online and offline offerings (collectively, the
            &ldquo;
          </span>
          <span className="c3">Services</span>
          <span className="c2">
            &rdquo;). This policy also explains your choices surrounding how we use
            your personal information, which include how you can object to certain
            uses of the information and how you can access and update certain
            information.
          </span>
        </p>
        <h2 className="c8" id="h.ylkeex60fyh5">
          <span className="c5 c3">1. Personal Information we collect</span>
        </h2>
        <p className="c0">
          <span className="c2">
            We collect personal information when you provide it to us, when you use
            our Services, and when other sources provide it to us, as further
            described below.
          </span>
        </p>
        <p className="c0">
          <span className="c7 c3">A. Information You Provide to Us</span>
        </p>
        <p className="c0">
          <span className="c3">Account Creation:</span>
          <span className="c2">
            &nbsp;When you create an account or otherwise use the Services, we
            collect information such as your name, email address, password.
          </span>
        </p>
        <p className="c0">
          <span className="c3">Your Communications with Us:</span>
          <span className="c2">
            &nbsp;We collect personal information from you such as email address,
            phone number, or mailing address when you request information about our
            Services, register for our newsletter, request customer or technical
            support, or otherwise communicate with us. We also collect the contents
            of messages or attachments that you may send to us, as well as other
            information you choose to provide, and that may be associated with your
            communications.
          </span>
        </p>
        <p className="c0">
          <span className="c3">Payment Information:</span>
          <span className="c2">
            &nbsp;When you choose to subscribe for the Premium Plan on our Services,
            we will collect payment information allowing you to pay us. We use
            third-party payment providers to process payments on the Services. We may
            receive information associated with your payment information, such as
            billing address and transaction information, but we do not directly store
            payment information on the Services.
          </span>
        </p>
        <p className="c0">
          <span className="c7 c3">B. Information Collected Automatically</span>
        </p>
        <p className="c0">
          <span className="c3">Automatic Data Collection:</span>
          <span className="c2">
            &nbsp;We keep track of certain information about you when you visit and
            interact with any of our Services. This information may include your
            Internet protocol (IP) address, user settings, MAC address, cookie
            identifiers, mobile carrier, mobile advertising and other unique
            identifiers, details about your browser, operating system or device,
            location information (including inferred location based off of your IP
            address), Internet service provider, pages that you visit before, during
            and after using the Services, information about the links you click,
            information about how you interact with the Services, including the
            frequency and duration of your activities, and other information about
            how you use the Services. Information we collect may be associated with
            accounts and other devices.
          </span>
        </p>
        <p className="c0">
          <span className="c3">
            Cookies, Pixel Tags/Web Beacons, and Analytics Information:
          </span>
          <span className="c2">
            &nbsp;We, as well as third parties that provide content, payment, or
            other functionality on the Services, may use cookies, pixel tags, local
            storage, and other technologies (&ldquo;Technologies&rdquo;) to
            automatically collect information through the Services. Technologies are
            essentially small data files placed on your devices that allow us and our
            partners to record certain pieces of information whenever you visit or
            interact with our Services.
          </span>
        </p>
        <ul className="c6 lst-kix_rcbh3w7mqg2-0 start">
          <li className="c0 c4 li-bullet-0">
            <span className="c3">Cookies:</span>
            <span className="c2">
              &nbsp;Cookies are small text files placed in device browsers to store
              their preferences. Most browsers allow you to block and delete cookies.
              However, if you do that, the Services may not work properly.
            </span>
          </li>
          <li className="c0 c4 li-bullet-0">
            <span className="c3">Pixel Tags/Web Beacons:</span>
            <span className="c2">
              &nbsp;A pixel tag (also known as a web beacon) is a piece of code
              embedded in the Services that collects information about engagement on
              the Services. The use of a pixel tag allows us to record, for example,
              that a user has visited a particular web page or clicked on a
              particular link. We may also include web beacons in e-mails to
              understand whether messages have been opened, acted on, or forwarded.
            </span>
          </li>
        </ul>
        <p className="c0">
          <span className="c3">Analytics:</span>
          <span className="c2">
            &nbsp;We may also use third-party service providers to collect and
            process analytics and other information on our Services. These
            third-party service providers may use cookies, pixel tags, web beacons or
            other storage technology to collect and store analytics and other
            information. They have their own privacy policies addressing how they use
            the analytics and other information and we do not have access to, nor
            control over, third parties&rsquo; use of cookies or other tracking
            technologies.
          </span>
        </p>
        <p className="c0">
          <span className="c7 c3">C. Information from Other Sources</span>
        </p>
        <p className="c0">
          <span className="c2">
            We may obtain information about you from other sources, including through
            third-party services and organizations. For example, if you access our
            Services through a third-party application, such as a social networking
            site or a third-party login service, we may collect information about you
            from that third party that you have made available via your privacy
            settings.
          </span>
        </p>
        <h2 className="c8" id="h.qm43ghsu7jd1">
          <span className="c5 c3">2. How we use your information</span>
        </h2>
        <p className="c0">
          <span className="c2">
            We use your personal information for a variety of business purposes,
            including to:
          </span>
        </p>
        <p className="c0">
          <span className="c7 c3">
            Provide the Services or Requested Information, such as:
          </span>
        </p>
        <ul className="c6 lst-kix_q0fi931ef9ae-0 start">
          <li className="c0 c4 li-bullet-0">
            <span className="c2">Fulfilling our contract with you;</span>
          </li>
          <li className="c0 c4 li-bullet-0">
            <span className="c2">
              Identifying and communicating with you, including providing newsletters
              and marketing materials;
            </span>
          </li>
          <li className="c0 c4 li-bullet-0">
            <span className="c2">Managing your information;</span>
          </li>
          <li className="c0 c4 li-bullet-0">
            <span className="c2">
              Processing your payments and otherwise servicing your purchase orders;
            </span>
          </li>
          <li className="c0 c4 li-bullet-0">
            <span className="c2">
              Responding to questions, comments, and other requests;
            </span>
          </li>
          <li className="c0 c4 li-bullet-0">
            <span className="c2">
              Providing access to certain areas, functionalities, and features of our
              Services; and
            </span>
          </li>
          <li className="c0 c4 li-bullet-0">
            <span className="c2">
              Answering requests for customer or technical support.
            </span>
          </li>
        </ul>
        <p className="c0">
          <span className="c7 c3">Serve Administrative Purposes, such as:</span>
        </p>
        <ul className="c6 lst-kix_rhn1fqeyzl8d-0 start">
          <li className="c0 c4 li-bullet-0">
            <span className="c2">
              Pursuing legitimate interests, such as direct marketing, research and
              development (including marketing research), network and information
              security, and fraud prevention;
            </span>
          </li>
          <li className="c0 c4 li-bullet-0">
            <span className="c2">
              Measuring interest and engagement in our Services, including analyzing
              your usage of the Services;
            </span>
          </li>
          <li className="c0 c4 li-bullet-0">
            <span className="c2">
              Developing new products and services and improving the Services;
            </span>
          </li>
          <li className="c0 c4 li-bullet-0">
            <span className="c2">Ensuring internal quality control and safety;</span>
          </li>
          <li className="c0 c4 li-bullet-0">
            <span className="c2">
              Authenticating and verifying individual identities;
            </span>
          </li>
          <li className="c0 c4 li-bullet-0">
            <span className="c2">Carrying out audits;</span>
          </li>
          <li className="c0 c4 li-bullet-0">
            <span className="c2">
              Communicating with you about your account, activities on our Services
              and Privacy Policy changes;
            </span>
          </li>
          <li className="c0 c4 li-bullet-0">
            <span className="c2">
              Preventing and prosecuting potentially prohibited or illegal
              activities;
            </span>
          </li>
          <li className="c0 c4 li-bullet-0">
            <span className="c2">Enforcing our agreements; and</span>
          </li>
          <li className="c0 c4 li-bullet-0">
            <span className="c2">Complying with our legal obligations.</span>
          </li>
        </ul>
        <p className="c0">
          <span className="c3">Consent:</span>
          <span className="c2">
            &nbsp;We may use personal information for other purposes that are clearly
            disclosed to you at the time you provide personal information or with
            your consent.
          </span>
        </p>
        <p className="c0">
          <span className="c3">De-identified and Aggregated Information Use:</span>
          <span className="c2">
            &nbsp;We may use personal information and other data about you to create
            de-identified and/or aggregated information. De-identified and/or
            aggregated information is not personal information, and we may use and
            disclose such information in a number of ways, including research,
            internal analysis, analytics, and any other legally permissible purposes.
          </span>
        </p>
        <p className="c0">
          <span className="c3">How We Use Automatic Collection Technologies:</span>
          <span className="c2">
            &nbsp;Our uses of Technologies fall into the following general
            categories:
          </span>
        </p>
        <ul className="c6 lst-kix_lmswnsjiswin-0 start">
          <li className="c0 c4 li-bullet-0">
            <span className="c2">Operationally Necessary;</span>
          </li>
          <li className="c0 c4 li-bullet-0">
            <span className="c2">Performance Related;</span>
          </li>
          <li className="c0 c4 li-bullet-0">
            <span className="c2">Functionality Related; and</span>
          </li>
        </ul>
        <h2 className="c8" id="h.7dpxymo0bdex">
          <span className="c5 c3">
            3. Disclosing your information to third parties
          </span>
        </h2>
        <p className="c0">
          <span className="c2">
            We may share your personal information with the following categories of
            third parties:
          </span>
        </p>
        <p className="c0">
          <span className="c3">Service Providers:</span>
          <span className="c2">
            &nbsp;We may share any personal information we collect about you with our
            third-party service providers. The categories of service providers to
            whom we entrust personal information include service providers for: (i)
            the provision of the Services; (ii) the provision of information,
            products, and other services you have requested; (iii) payment and
            transaction processing; (iv) customer service activities; and (v) the
            provision of IT and related services.
          </span>
        </p>
        <p className="c0">
          <span className="c3">Business Partners:</span>
          <span className="c2">
            &nbsp;We may provide personal information to business partners to provide
            you with a product or service you have requested. We may also provide
            personal information to business partners with whom we jointly offer
            products or services.
          </span>
        </p>
        <p className="c0">
          <span className="c3">Contexts Accessible by Other Users:</span>
          <span className="c2">
            &nbsp;When you submit personal information in tasks and notes in a
            context that can be accessed by other users, such personal information
            may be displayed to those users. You will always explicitly state who can
            have access to your contexts, and contexts and data within them cannot be
            shared without your consent or knowledge.
          </span>
        </p>
        <p className="c0">
          <span className="c3">Disclosures to Protect Us or Others:</span>
          <span className="c2">
            &nbsp;We may access, preserve, and disclose any information we store in
            association with you to external parties if we, in good faith, believe
            doing so is required or appropriate to: (i) comply with law enforcement
            or national security requests and legal process, such as a court order or
            subpoena; (ii) protect your, our, or others&rsquo; rights, property, or
            safety; (iii) enforce our policies or contracts; or (iv) assist with an
            investigation and prosecution of suspected or actual illegal activity.
          </span>
        </p>
        <p className="c0">
          <span className="c3">
            Disclosure in the Event of Merger, Sale, or Other Asset Transfer:
          </span>
          <span className="c2">
            &nbsp;If we are involved in a merger, acquisition, financing due
            diligence, reorganization, bankruptcy, receivership, purchase or sale of
            assets, or transition of service to another provider, then your
            information may be transferred as part of such a transaction, as
            permitted by law and/or contract.
          </span>
        </p>
        <h2 className="c8" id="h.qzbc4rj7q60a">
          <span className="c5 c3">4. International Data Transfers</span>
        </h2>
        <p className="c0">
          <span className="c2">
            All information processed by us may be transferred, processed, and stored
            anywhere in the world, including but not limited to, the United States or
            other countries, which may have data protection laws that are different
            from the laws where you live. We endeavor to safeguard your information
            consistent with the requirements of applicable laws.
          </span>
        </p>
        <h2 className="c8" id="h.9wpx8e6llomn">
          <span className="c5 c3">5. Your Choices</span>
        </h2>
        <p className="c0">
          <span className="c3">General:</span>
          <span className="c2">
            &nbsp;You may have the right to object to or opt out of certain uses of
            your personal information. Where you have consented to the processing of
            your personal information, you may withdraw that consent at any time by
            contacting us as described below. Even if you opt out, we may still
            collect and use non-personal information regarding your activities on our
            Services and for other legal purposes as described above.
          </span>
        </p>
        <p className="c0">
          <span className="c3">Email Communications:</span>
          <span className="c2">
            &nbsp;If you receive an unwanted email from us, you can use the
            unsubscribe link found at the bottom of the email to opt out of receiving
            future emails. Note that you will continue to receive transaction-related
            emails regarding products or services you have requested. We may also
            send you certain non-promotional communications regarding us and our
            Services, and you will not be able to opt out of those communications
            (e.g., communications regarding the Services or updates to this Privacy
            Policy).
          </span>
        </p>
        <p className="c0">
          <span className="c3">Mobile Devices:</span>
          <span className="c2">
            &nbsp;We may send you push notifications through our Apps. You may at any
            time opt out from receiving these types of communications by changing the
            settings on your mobile device. With your consent, we may also collect
            precise location information if you use our Apps. You may opt out of this
            collection by changing the settings on your mobile device.
          </span>
        </p>
        <p className="c0">
          <span className="c3">&ldquo;Do Not Track&rdquo;:</span>
          <span className="c2">
            &nbsp;Your browser may offer you a &ldquo;Do Not Track&rdquo; option,
            which allows you to signal to operators of websites and web applications
            and Service that you do not wish such operators to track certain of your
            online activities over time and/or across different websites. If your
            browser is set to &ldquo;Do Not Track&rdquo;, EasyBusy will attempt to
            honor this functionality. However, our third party service providers may
            use their own cookies, pixel tags, web beacons or other storage
            technology to collect and store Log Data or information from elsewhere on
            the internet, and we do not have access to, nor control over, a third
            parties&rsquo; use of cookies or other tracking technologies.
          </span>
        </p>
        <p className="c0">
          <span className="c3">Cookies and Interest-Based Advertising:</span>
          <span>
            &nbsp;You may stop or restrict the placement of Technologies on your
            device or remove them by adjusting your preferences as your browser or
            device permits. Please note that cookie-based opt-outs are not effective
            on mobile applications. However, you may opt-out of personalized
            advertisements on some mobile applications by following the instructions
            for
          </span>
          <span>
            <a
              className="c1"
              href="https://www.google.com/url?q=https://support.google.com/googleplay/android-developer/answer/6048248?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1618254970166000&amp;usg=AOvVaw1NUFFDmRy7XIepNH3TYLFw"
            >
              &nbsp;
            </a>
          </span>
          <span className="c9">
            <a
              className="c1"
              href="https://www.google.com/url?q=https://support.google.com/googleplay/android-developer/answer/6048248?hl%3Den&amp;sa=D&amp;source=editors&amp;ust=1618254970167000&amp;usg=AOvVaw1xFfYPZSc0SHYbbtAzfG4v"
            >
              Android
            </a>
          </span>
          <span>&nbsp;and</span>
          <span>
            <a
              className="c1"
              href="https://www.google.com/url?q=https://support.apple.com/en-us/HT202074&amp;sa=D&amp;source=editors&amp;ust=1618254970167000&amp;usg=AOvVaw09--J6fiyXXHApzp6AZkkG"
            >
              &nbsp;
            </a>
          </span>
          <span className="c9">
            <a
              className="c1"
              href="https://www.google.com/url?q=https://support.apple.com/en-us/HT202074&amp;sa=D&amp;source=editors&amp;ust=1618254970167000&amp;usg=AOvVaw09--J6fiyXXHApzp6AZkkG"
            >
              iOS
            </a>
          </span>
          <span>
            . The online advertising industry also provides websites from which you
            may opt out of receiving targeted ads from data partners and other
            advertising partners that participate in self-regulatory programs. You
            can access these websites and learn more about targeted advertising and
            consumer choice and privacy, at
          </span>
          <span>
            <a
              className="c1"
              href="https://www.google.com/url?q=http://www.networkadvertising.org/managing/opt_out.asp&amp;sa=D&amp;source=editors&amp;ust=1618254970168000&amp;usg=AOvVaw1mE-6gAd44KvkGBZyYI2wF"
            >
              &nbsp;
            </a>
          </span>
          <span className="c9">
            <a
              className="c1"
              href="https://www.google.com/url?q=http://www.networkadvertising.org/managing/opt_out.asp&amp;sa=D&amp;source=editors&amp;ust=1618254970168000&amp;usg=AOvVaw1mE-6gAd44KvkGBZyYI2wF"
            >
              www.networkadvertising.org/managing/opt_out.asp
            </a>
          </span>
          <span>,</span>
          <span>
            <a
              className="c1"
              href="https://www.google.com/url?q=http://www.youronlinechoices.eu/&amp;sa=D&amp;source=editors&amp;ust=1618254970168000&amp;usg=AOvVaw2HqIX36q-Vg3gvjSO9pKLT"
            >
              &nbsp;
            </a>
          </span>
          <span className="c9">
            <a
              className="c1"
              href="https://www.google.com/url?q=http://www.youronlinechoices.eu/&amp;sa=D&amp;source=editors&amp;ust=1618254970168000&amp;usg=AOvVaw2HqIX36q-Vg3gvjSO9pKLT"
            >
              http://www.youronlinechoices.eu/
            </a>
          </span>
          <span>,</span>
          <span>
            <a
              className="c1"
              href="https://www.google.com/url?q=https://youradchoices.ca/choices/&amp;sa=D&amp;source=editors&amp;ust=1618254970169000&amp;usg=AOvVaw2jJxQPuM5zROA0LtBNnUXl"
            >
              &nbsp;
            </a>
          </span>
          <span className="c9">
            <a
              className="c1"
              href="https://www.google.com/url?q=https://youradchoices.ca/choices/&amp;sa=D&amp;source=editors&amp;ust=1618254970169000&amp;usg=AOvVaw2jJxQPuM5zROA0LtBNnUXl"
            >
              https://youradchoices.ca/choices/
            </a>
          </span>
          <span>, and</span>
          <span>
            <a
              className="c1"
              href="https://www.google.com/url?q=http://www.aboutads.info/choices/&amp;sa=D&amp;source=editors&amp;ust=1618254970169000&amp;usg=AOvVaw1esuNhSisx3r4y9P2LalXO"
            >
              &nbsp;
            </a>
          </span>
          <span className="c9">
            <a
              className="c1"
              href="https://www.google.com/url?q=http://www.aboutads.info/choices/&amp;sa=D&amp;source=editors&amp;ust=1618254970169000&amp;usg=AOvVaw1esuNhSisx3r4y9P2LalXO"
            >
              www.aboutads.info/choices/
            </a>
          </span>
          <span className="c2">.</span>
        </p>
        <p className="c0">
          <span className="c2">
            Please note you must separately opt out in each browser and on each
            device.
          </span>
        </p>
        <h2 className="c8" id="h.nuwl9um011ck">
          <span className="c5 c3">6. Your Privacy Rights</span>
        </h2>
        <p className="c0">
          <span className="c2">
            Depending upon your location and in accordance with applicable laws, you
            may have the right to:
          </span>
        </p>
        <ul className="c6 lst-kix_mmign93tqf8v-0 start">
          <li className="c0 c4 li-bullet-0">
            <span className="c3">Access</span>
            <span className="c2">
              &nbsp;personal information about you consistent with legal
              requirements. In addition, you may have the right in some cases to
              receive or have your electronic personal information transferred to
              another party.
            </span>
          </li>
          <li className="c0 c4 li-bullet-0">
            <span className="c3">Request Correction</span>
            <span className="c2">
              &nbsp;of your personal information where it is inaccurate or
              incomplete.
            </span>
          </li>
          <li className="c0 c4 li-bullet-0">
            <span className="c3">Request Deletion</span>
            <span className="c2">
              &nbsp;of your personal information, subject to certain exceptions
              prescribed by law.
            </span>
          </li>
          <li className="c0 c4 li-bullet-0">
            <span className="c3">Request Restriction or Object to Processing</span>
            <span className="c2">
              &nbsp;of your personal information, including the right to opt in or
              opt out of the sale of your personal information to third parties.
            </span>
          </li>
          <li className="c0 c4 li-bullet-0">
            <span className="c3">Not be Discriminated Against</span>
            <span className="c2">
              &nbsp;by us for exercising your privacy rights.
            </span>
          </li>
        </ul>
        <p className="c0">
          <span className="c2">
            If you would like to exercise any of these rights, please contact us as
            set forth below. We will process such requests in accordance with
            applicable laws. To protect your privacy, we will take steps to verify
            your identity before fulfilling your request, such as by requiring you to
            submit your request via your account.
          </span>
        </p>
        <h2 className="c8" id="h.md8dbept6ptx">
          <span className="c3 c5">7. Data Retention</span>
        </h2>
        <p className="c0">
          <span className="c2">
            We store the personal information we receive as described in this Privacy
            Policy for as long as you use our Services or as necessary to fulfill the
            purpose(s) for which it was collected, provide our Services, resolve
            disputes, establish legal defenses, conduct audits, pursue legitimate
            business purposes, enforce our agreements, and comply with applicable
            laws.
          </span>
        </p>
        <h2 className="c8" id="h.sdxtie7u73l">
          <span className="c5 c3">8. Security of your Information</span>
        </h2>
        <p className="c0">
          <span className="c2">
            We take steps to ensure that your information is treated securely and in
            accordance with this Privacy Policy. Unfortunately, no system is 100%
            secure, and we cannot ensure or warrant the security of any information
            you provide to us. To the fullest extent permitted by applicable law, we
            do not accept liability for unauthorized disclosure.
          </span>
        </p>
        <p className="c0">
          <span className="c2">
            By using the Services or providing personal information to us, you agree
            that we may communicate with you electronically regarding security,
            privacy, and administrative issues relating to your use of the Services.
            If we learn of a security system&rsquo;s breach, we may attempt to notify
            you electronically by posting a notice on the Services, by sending an
            email to you.
          </span>
        </p>
        <h2 className="c8" id="h.q7m4g08qiuf9">
          <span className="c5 c3">9. Third-Party Websites/Applications</span>
        </h2>
        <p className="c0">
          <span className="c2">
            The Services may contain links to other websites/applications and other
            websites/applications may reference or link to our Services. These
            third-party services are not controlled by us. We encourage our users to
            read the privacy policies of each website and application with which they
            interact. We do not endorse, screen or approve, and are not responsible
            for, the privacy practices or content of such other websites or
            applications. Visiting these other websites or applications is at your
            own risk.
          </span>
        </p>
        <h2 className="c8" id="h.qketsczglawp">
          <span className="c5 c3">10. Children&#39;s Information</span>
        </h2>
        <p className="c0">
          <span className="c2">
            The Services are not directed to children under 13 (or other age as
            required by local law), and we do not knowingly collect personal
            information from children. If you learn that your child has provided us
            with personal information without your consent, you may contact us as set
            forth below. If we learn that we have collected a child&rsquo;s personal
            information in violation of applicable law, we will promptly take steps
            to delete such information and terminate the child&rsquo;s account.
          </span>
        </p>
        <h2 className="c8" id="h.xnsqtfi900p5">
          <span className="c5 c3">11. Supervisory Authority</span>
        </h2>
        <p className="c0">
          <span className="c2">
            If you are located in the European Economic Area or the UK, you have the
            right to lodge a complaint with a supervisory authority if you believe
            our processing of your personal information violates applicable law.
          </span>
        </p>
        <h2 className="c8" id="h.181clkyuyu7r">
          <span className="c5 c3">12. California Privacy Notice</span>
        </h2>
        <p className="c0">
          <span className="c2">
            This Privacy Notice applies to California consumers and supplements the
            Privacy Policy.
          </span>
        </p>
        <p className="c0">
          <span className="c3">California Shine the Light Law:</span>
          <span className="c2">
            &nbsp;The California &ldquo;Shine the Light&rdquo; law permits users who
            are California residents to request and obtain from us once a year, free
            of charge, a list of the third parties to whom we have disclosed their
            personal information (if any) for their direct marketing purposes in the
            prior calendar year, as well as the type of personal information
            disclosed to those parties. To make such a request from us, if entitled,
            please use the contact information listed below.
          </span>
        </p>
        <p className="c0">
          <span className="c3">California Consumer Privacy Act (CCPA):</span>
          <span className="c2">
            &nbsp;In the preceding 12 months, we have not disclosed your personal
            information to any third party in a manner that would be considered a
            sale under the CCPA.
          </span>
        </p>
        <p className="c0">
          <span className="c3 c7">Information We Collect and Disclose</span>
        </p>
        <p className="c0">
          <span className="c2">
            For purposes of the CCPA, we have collected the following categories of
            consumers&rsquo; personal information, and disclosed the personal
            information to the following categories of third parties for a business
            purpose, in the last 12 months:
          </span>
        </p>
        <h2 className="c8" id="h.fu5jao2egdr2">
          <span className="c5 c3">13. Changes to Our Privacy Policy</span>
        </h2>
        <p className="c0">
          <span className="c2">
            We may revise this Privacy Policy from time to time in our sole
            discretion. If there are any material changes to this Privacy Policy, we
            will notify you as required by applicable law. You understand and agree
            that you will be deemed to have accepted the updated Privacy Policy if
            you continue to use the Services after the new Privacy Policy takes
            effect.
          </span>
        </p>
        <p className="c0">
          <span className="c7 c3">Contact us:</span>
        </p>
        <p className="c0">
          <span className="c2">
            If you have any questions about our privacy practices or this Privacy
            Policy, please contact us at:
          </span>
        </p>
        <p className="c0">
          <span className="c9">
            <a className="c1" href="mailto:support@easybusydo.com">
              support@easybusydo.com
            </a>
          </span>
          <span className="c2">&nbsp;</span>
        </p>
        <p className="c0">
          <span className="c2">Effective Date: April 12, 2021</span>
        </p>
        <p className="c10">
          <span className="c2" />
        </p>
      </div>
    </div>
  </Layout>
);

export default PrivacyPage;
